@import '../../scss/variables.scss';


.footer-navigation-action-wrapper.animate-in {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 270px;


    @media(max-width:$tablet){
      display:block;
      min-height:500px;
    }


    a {
      height:100%;
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background-size:cover;
      text-decoration:none;
      background-size:cover;
      background-position:center;
      border-top:1px solid whitesmoke;

      &:nth-child(1){
        border-right:1px solid whitesmoke;
      }

      span.sub-action-content {
        margin-left: 150px;

        @media(max-width:$tablet){
          margin-left:20px;
        }
    }

    &:hover .filter-hover {
          height:0%;
          transition:.3s;
          transition-delay:.4s;
        }

        &:hover .filter {
          margin-left:0%;
          width:100%;
          transition:.3s;
        }

        &:hover h5 {
          margin-left:25px;
          transition:.5s;
          transition-delay:.3s;
          color:#F44336;
        }
        &:hover p {
          margin-left:25px;
          transition:.5s;
          padding-right:20%;
        }
   


        h5 {
          position: relative;
          font-size: 4vw;
          color: #fdfdfd;
          text-transform: capitalize;
          font-family: 'Roboto', sans-serif;
          font-weight: 900;
          margin:0;
          transition:.5s;
          text-align:left;
          text-trasnform:uppercase;
                    font-family:'Work Sans',sans-serif;


          @media(max-width:$tablet){
            font-size: 40px;
            text-align: left;
            margin-left:0 !important
          }

        }
        p{
          position: relative;
          color: #fff;
          width: 200px;
          border-top: 1px solid rgba(255,255,255,0.3);
          padding-top: 10px;
          transition:.5s;
          text-align:left;
          font-size:18px;
          margin-top:10px;
          width:230px;
          font-size:18px;
          line-height:110%;

          @media(max-width:$tablet){
            margin-left: 0 !important;
            text-align: left;
            margin-right: 0 !important;
            margin: auto;
            margin-top: 15px;
          }
        }

        a {
          text-decoration:none;
        }

        .filter-hover {
            position: absolute;
            width: 100%;
            height: 100%;
            background:$white;
            left:0;
            top:0;
            transition:.5s;


        }
        span.filter {
            position: absolute;
            bottom: 0;
            width: 100%;
            margin-left:60%;
            height: 100%;
            transition:.5s;
            background: $white;
            right: 0;
          
        }
    }
}


.footer-navigation-action-wrapper.animate-in.animate-show {

    a {
        top:0;
        left:0;
        height:100%;
        transition:.9s;
        transition-delay:.4s;
        background: rgb(158,103,222);
        background: linear-gradient(236deg, rgba(158,103,222,1) 0%, rgba(118,1,253,1) 100%) !important;
        @media(max-width:$tablet){
          height:250px;
        }

      &:hover .filter-hover {
          height:0%;
          transition:.3s;
          transition-delay:.4s;


        }

        &:hover .filter {
          margin-left:0%;
          width:100%;
          transition:.3s;
        }

        &:hover .sub-action-content h5 {
          margin-left:35px;
          transition:.5s;
          transition-delay:.3s;
          color:$white;

        }
        &:hover p {
          margin-left:25px;
          transition:.5s;
          padding-right:20%;
          border-top:none;
          color:$white;
        }
   
    }

    span.filter {
            position: absolute;
            bottom: 0;
            width: 40%;
            margin-left:60%;
            height: 100%;
            transition:.55s ease-in-out;
            background: rgba(0,0,0,0.1);
            transition-delay:.5s;
            right: 0;

          
        }
     .filter {
          margin-left:0%;
          width:50%;
          transition:.3s;
        }

      h5 {
          margin-left:25px;
          transition:.5s;
          transition-delay:.3s;
          color:$secondary-base-color;
          text-transform:uppercase;
        }
      p {
          margin-left:25px;
          transition:.5s;
          padding-right:0%;
          color:$secondary-base-color;
        }



}
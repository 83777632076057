body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
}

h2 {
  font-family:'Work Sans', sans-serif !important;
}
h1,h2,h4,h5,h5,li,p {
  line-height:100%;
}
p {
  font-size:13px;
  font-family:'Helvetica Neue',Helvetica,arial,sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.grape-white {
  color:rgba(0,0,0,0.0) !important;
  transition:.4s;
}
.web-svg-one {
     stroke-dasharray: 4000;
    stroke-dashoffset: 4000;
    animation: dash 5s linear forwards;
    animation-delay:2.6s;
    fill:transparent; 
}
.https {
  animation:blinkIt 2.2s infinite;
  stroke:#9473e8;
  animation-delay:2.5s;
  fill:transparent;


}
.blink-host {

   animation:blinkItTwo 2.2s infinite;
  stroke:#f44336;
  animation-delay:3.5s;
  fill:transparent;
  opacity:0;

}

  @keyframes blinkItTwo {
    0%{
      stroke:transparent;
    }
    50%{
      stroke:#9473e8;
    }
    100%{
      stroke:transparent;
      opacity:1;
    }
  }
  @keyframes blinkIt {
    0%{
      fill:transparent;
    }
    50%{
      fill:#9473e8;
    }
    100%{
      fill:transparent;
    }
  }
  .hosting-path{
    stroke-dasharray: 4000;
    stroke-dashoffset: 4000;
    animation: dash 5s linear forwards;
    animation-delay:2.6s;
    fill:transparent;
      } 

  @keyframes dash {
  to {
    stroke-dashoffset: 0;


  }
}
@import './../scss/variables.scss';


.App {
  text-align: center;
  background:$primary-dark;
  min-height:100vh;
  width:100%;
}


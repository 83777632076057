@import '../../scss/variables.scss';

.case-studies-content-wrapper.animate-in {
  position:relative;

     .case-studie-text-wrapper {
      text-align: left;
      margin: 0 0px 60px 175px;
      font-size: 18px;
      transform: skewX(-5deg);
      animation-delay:1s;
      animation:caseIntroWrapper .6s 1;
      animation-fill-mode:forwards;

      @media(max-width:$tablet){
        margin: 0 0 60px 26px;
      }

      @keyframes caseIntroWrapper {
        0%{
          transform:skewX(-5deg);
        }
        100%{ 
          transform:skewX(0deg);
        }
      } 

      p {
        top:15px;
        opacity:0;
        position:relative;
        animation:caseIntroP 1.2s 1;
        animation-fill-mode:forwards;
        animation-delay:2s;
        width: 40%;
        transform: skewX(-5deg);
        color:$primary-font-color;
        font-size:18px;
        line-height:130%;

        @media(max-width:$tablet){
          width:80%;
        }

        @keyframes caseIntroP {
          0%{
            top:45px;
          }
          100%{
            top:0;
            opacity:1;
            transform: skewX(0deg);
          }
        }
      }
  }



 
  span.miny-sub-heading-wrapper.initial-transition-header.animate-in{
    display: block;
    top: 0;
    position: relative;
    width: 0px;
    margin-bottom: 15px;
    width: 152px;
    margin: 0 0 40px 137px;

    @media(max-width:$tablet){
      margin: 0 0 40px 56px;
    }

 

    
    span:nth-child(1) {
      width: 8px;
      position: absolute;
      height: 2px;
      background: $primary-font-color;
      margin-top: 10px;
      left: -31px;
      transition: .9s;
      top: 3px;
      opacity: 0;
      animation: serviceListin .8s 1;
      animation-fill-mode: forwards;
      animation-delay: 1.8s;

      @keyframes serviceListin {
        0%{
          width:8px;
          opacity:0;
        }
        100%{
          width:50px;
          opacity:1;
        }
      }
  }

  span:nth-child(2){
    font-size: 12px;
    text-transform: uppercase;
    color: $secondary-base-color;
    transition:.9s;
    opacity:0;
    position:relative;
    right:-20px;
    animation:serviceListinTwo .8s 1;
    animation-fill-mode:forwards;
    animation-delay:1.8s;
    font-weight:900;

      @keyframes serviceListinTwo {
        0%{
          right:-20px;
          opacity:0;
        }
        100%{
          right:-0px;
          opacity:1;
        }
      }
  }
}

  .number-item-wrapper {
      position: absolute;
      top:15px;
      opacity:0;
      z-index: 3;
      transition:.5s;


  }

  .cases-wrapper.animate-in {
      width: calc(100% - 130px);
      min-height: 400px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding-left: 130px;
      background: $primary-dark;

      @media(max-width:$tablet){
        padding-left:25px;
       width:calc(100% - 25px);
      }


      div {
        height: 280px;
        flex: 35%;
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 50px;
        background-size:cover;
        background-position:center;
        opacity:0;
        top:50px;
        transition:1s;


        &:hover .filter-hover {
          height:0%;
          transition:.4s;
          transition-delay:s;
          background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);
        }

        &:hover .filter {
          margin-left:0%;
          width:100%;
          transition:.4s;
        }

        &:hover h5 {
          margin-left:25px;
          transition:.5s;
          color:#fdfdfd !important;

        }
        &:hover p {
          margin-left:25px;
          transition:.5s;
          padding-right:20%;
          border-top: 2px solid rgb(255,255,255) !important;
           color:#fdfdfd !important;
        }

   


        h5 {
          position: relative;
          font-size: 2em;
          color: $white;
          text-transform: capitalize;
          font-family: 'Work Sans','Roboto',sans-serif;
          font-weight: 900;
          margin:0;
          transition:.5s;
          text-align:left;
          width:100%;
          text-transform:uppercase;

          @media(max-width:$tablet){
            font-size:40px;
          }

        }
        p{
          position: relative;
          color: $white;
          width: 200px;
          border-top: 2px solid $white;
          padding-top: 10px;
          transition:.5s;
          text-align:left;
          font-size:16px;
          margin-bottom:5px;
          line-height:120%;
        }
        p.date {
            border-top: none !important;
            font-size: 11px;
            color: $white;
            font-weight: bold;
        }
        p.collab {
          border: none !important;
         
          padding: 0;
          color: $white;
          font-weight: bold;
          font-size: 12px;
        }

        a {
          text-decoration:none;
        }

        .filter-hover {
            position: absolute;
            width: 60%;
            height: 100%;
            background: $secondary-base-color;
            left:0;
            top:0;
            transition:.5s;
            background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);
            @media(max-width:$tablet){
              width:80%;
            }


        }

        span.init-filter {
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.3);
          left: 0;
          top: 0;
          position: absolute;
      }
        span.filter {
            position: absolute;
            bottom: 0;
            width: 60%;
            margin-left: 60%;
            height: 100%;
            transition: .5s;
            transition-delay: .5s;
            right: auto;
            left: 0;
            margin-left: 0;
           background:$tertiary-base-color;
           background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);
                  
        }
    }
    

     

  }

  .cases-wrapper.animate-in.animate-show {
      .number-item-wrapper {
      opacity:1;
      top:95px !important;
      transition-delay:1s;

      @media(max-width:$tablet){
        display:none;
      }
    }
    div {
      top:0px;
      transition:.9s;
      opacity:1;
      position:relative;
      @media(max-width:$tablet){
        padding-left:15px;
      }

       @for $i from 1 to 1000 {
         &:nth-child(#{$i}) { transition-delay: $i * 0.35s; }
     }
    }

  }
}

  


.case-studies-wrapper.animate-in.animate-show {
  position:relative;

  .number-item-wrapper {
      position: absolute;
      top:0px;
      transition-delay:.4s;
      opacity:1;
      transition:.9s;
      z-index: 3;


  }


}
@import '../../scss/variables.scss';

.services-services-offering-wrapper.animate-in {
    width: 100%;
    position: relative;
    min-height: 40vh;
    padding-bottom:50px;


    .svg-web-wrapper {
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      width: 80%;
      margin-left: 170px;

      @media(max-width:$tablet){
        margin-left: 25px;
        display: block;
      }

     

      a.service-action-link {
        border-bottom: 2px solid;
        display: inline-block;
        margin-top: 21px;
        font-weight: bold;
        color:#333;
        text-decoration: none;
        font-size: 15px;
        padding-bottom: 5px;
        transition:1s;

        &:hover {
          color: #f44236;
          transition: .4s;
      }
    }
    
     ul.technologies-used-wrapper {

        li {
          font-weight: bold;
          color: $secondary-base-color;
          font-size:16px;
          position:relative;
          &::before {
            content:'';
            width:5px;
            height:5px;
            border-radius:100%;
            background:#333;
            position:absolute;
            left:-10px;
            top:10px;
          }
        }
      }



      div {
        flex: .9;
        text-align:left;

        li{
          transform: skewX(-33deg);
          top: 20px;
          position: relative;
          opacity:0;
          transition:1s;
          animation-delay:0;
          color:$primary-font-color;


        span {
            font-weight: bold;
        }
        span.special-circumstance {
            font-size: 12px;
        }
        a {
          color:$secondary-base-color;
        }

      }

        h4 {
          font-size: 2.2vw;
          margin: 15px 0px 8px 0px;
          line-height: 80%;
          color:$primary-font-color;
          font-family:'Work Sans',sans-serif;
          text-transform:uppercase;
          line-height:85%;

          @media(max-width:$tablet){
            font-size:23px;
          }
        }

        ul {
          flex: .3;
          text-align: left;
          list-style: none;
          text-align: left;
          font-size: 18px;
          padding-left:0;
          box-sizing: border-box;
          padding: 0 100px 0 0px;
          transform: rotate(-1deg);
          top: 20px;
          position: relative;
          transition:.6s;
          opacity:0;

          @media(max-width:$tablet){
            padding-right:60px;
            padding-bottom:80px;
          }
      }

        svg {
             width: 37%;
             position:relative;
             top:25px;
             opacity:0;
             transition:.4s;
             animation:svgIn .9s 1;
             animation-fill-mode:forwards;
             animation-delay:2.3s;

             @keyframes svgIn {
              0%{
                opacity:0;
                top:25px;
              }
              100%{
                top:0;
                opacity:1;

              }
             }

      }
    }
  }


    
}

.services-services-offering-wrapper.animate-in.animate-show {

  .svg-web-wrapper div li{
    transform: skewX(0deg);
    top: 0px;
    opacity:1;
    transition:.9s;
    font-size:18px;
    line-height:130%;

 
    @for $i from 1 to 1000 {
         &:nth-child(#{$i}) { transition-delay: $i * 0.2s; }
     }

  }

 


   .svg-web-wrapper ul {
    transform: rotate(0deg);
    top: 0px;
    position: relative;
    transition:1s;
    opacity:1;
    transition-delay:.6s;

  }
}

span.miny-sub-services-heading-wrapper {
    display: block;
    top: 0;
    position: relative;
    width: 0px;
    margin-bottom: 15px;
    width:194px;

    


    
    span:nth-child(1) {
      width: 8px;
      position: absolute;
      height: 2px;
      background: $primary-font-color;
      margin-top: 10px;
      left: -67px;
      transition: .9s;
      top: 3px;
      opacity:0;
      animation:serviceListin .8s 1;
      animation-fill-mode:forwards;
      animation-delay:1.8s;

      @media(max-width:$tablet){
        display:none;
      }

      @keyframes serviceListin {
        0%{
          width:8px;
          opacity:0;
        }
        100%{
          width:50px;
          opacity:1;
        }
      }
  }

  span:nth-child(2){
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: $secondary_base_color;
    transition:.9s;
    opacity:0;
    position:relative;
    right:-20px;
    animation:serviceListinTwo .8s 1;
    animation-fill-mode:forwards;
    animation-delay:1.8s;
    top:3px;
    font-weight:900;

      @keyframes serviceListinTwo {
        0%{
          right:-20px;
          opacity:0;
        }
        100%{
          right:-0px;
          opacity:1;
        }
      }
  }
}

@import '../../scss/variables.scss';

.single-work-page-wrapper.animate-in {



   .view-more {
      width: 83px;
    height: 87px;
    position: absolute;
    bottom: 0;
    z-index: 5;
    background: #7602fd;
    animation-fill-mode: forwards;
    cursor: pointer;
    margin-left: 176px !important;
    max-width: 184px;
    width: 100%;
    left:0;
    margin-left:0;

           @media(max-width:$tablet){
             height: 70px;
              margin-left: 20px !important;
              left: 0;
            }



      .span-more {
          color: #0a2832;
          display: flex;
          position: relative;
          top: 35px;
          font-weight: bold;
          font-size: 15px;
          width: 5px;
          height: 100%R;
          height: 50px;
          /* background: red; */
          height: 55px;
          top: 0;
          margin-top: 19px;
          margin-left: 93px;

          div {
            margin-left: 0 !important;
            width: 100% !important;
            left: 0 !important;
            right: auto !important;
        }

          div:nth-child(2){
              margin: auto;
              width: 84px;
              position: absolute;
              width: 100%;
              height: 3px;
              top: 8px;
              right: 104px;

               @media(max-width:$tablet){
                right:51px;
                }


              span {
               position: relative;
                width: 100%;
                height: 50%;
                right: 0;
                height: 100%;
                overflow: hidden;
                top: 0;
                height: 58px;
                overflow: hidden !important;
                width: 5px;
                display: block;
                overflow: hidden;
                @media(max-width:$tablet){
                  height:31px;
                }


                > span {
                 position: absolute;
                  width: 4px;
                  height: 100%;
                  background: #fff;
                  animation: spanAnimate 3.5s infinite;
                  top: 0;


                


                  @keyframes spanAnimate {
                    0%{
                      top:-150%;
                    }
                    100%{
                      top:100%;
                    }
                  }


                }

              }

          }
      }
}


  .single-work-body-content {

     color:$primary-font-color;
    text-align: left;
    margin-left: 175px;
    width: calc(100% - 375px);
    padding-bottom:100px;
    position:relative;

    @media(max-width:$tablet){
      width: calc(100% - 40px);
    margin-left: 20px;
    }

    

    span.scores-wrapper.animate-in {
      display: flex;
      width: 500px;
      background: $primary-font-color;
      padding-bottom:30px;
      border-left:1px solid;

      @media(max-width:$tablet){
            width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        display: block;
      }

      h5 {
        margin-bottom:0;
        padding-bottom:10px;
        color:$white;
      }

     span {
        flex: 1;
        text-align: center;

        p.score-good {
          border-radius: 100%;
          border: 2px solid #c8ff87;
          width: 50px;
          height: 50px;
          margin: auto;
          justify-content: center;
          align-items: center;
          display: flex;
          color: #c8ff87;
          font-weight: bold;
      }
      p.score-medium {
          border-radius: 100%;
          border: 2px solid #FFC107;
          width: 50px;
          height: 50px;
          margin: auto;
          justify-content: center;
          align-items: center;
          display: flex;
          color: #FFC107;
          font-weight: bold;
      }
    }

    img {
      margin: 20px 0px;
      width:100%;
    }
  }

    .traffic-score {
      color: #4CAF50;
      font-weight: bold;
  }
   
    span.collaboration {
      background: $tertiary-base-color;
      font-weight: normal;
      padding: 10px 13px;
      top: 0;
      position: relative;
      padding-top: 50px;
      color:#fff;
      font-weight:bold;
    }

    .sub-quote {
      border-left: 1px solid;
      padding: 35px 41px 35px 41px;
      background: $primary-font-color;
      box-sizing: border-box;
      color:$white;
      font-weight:bold;
    }
    p,li {
      font-size:16px;
      line-height:120%;
    }
    
    h3 {
      font-size:32px;
      line-height:100%;
         font-family:"Work Sans",sans-serif;
        text-transform:uppercase;
    }
    h4 {
      font-size:20px;
    }
    a {
      color:$secondary-base-color;
      transition:.5s;
      font-weight:bold;

      &:hover {
        color:#999;
        transition:.3s;
      }
    }
  }

  .prev-next-article-footer {

    display: flex;
    align-items: center;
    padding-bottom: 8px;
       font-family:"Work Sans",sans-serif;
        text-transform:uppercase;

    a  {
      margin: 0 30px 0 0px;
      font-weight: bold;
      font-size: 28px;
      text-decoration: none;
      transition: .5s;
      color: $primary-font-color;
      flex:1;

      &:hover {
        color:#999;
        transition:.3s;
      }
    }
  }

  .entry-text.article-on {


    .animate-line {
          position: absolute;
          left: 0;
          height: 1px;
          background: $white;
          width: 0;
          display: block;
          bottom: 0;
          animation:LineLeftRight .9s 1;
          animation-fill-mode:forwards;

          @keyframes LineLeftRight {
            0%{
              left:0;
              width:0;
            }
            100%{
              width:100%;
            }
          }
      }

  }

  .entry-text.article-off {

    .animate-line {
          position: absolute;
          right: 0;
          height: 1px;
          background: $tertiary-base-color;
          width: 0;
          display: block;
          bottom: 0;
          animation:RightToLeft .9s 1;
          animation-fill-mode:forwards;

          @keyframes RightToLeft {
            0%{
              right:0;
              width:0;
            }
            100%{
              width:100%;
            }
          }
      }

  }
  .entry-text{
    min-height:60vh;
    display:flex;
    align-items: center;
    padding-bottom:25px;
    position:relative;
    padding-top:55px;
    background:$white;





    .article-on {
      animation:articleOn .9s 1;
      animation-fill-mode:forwards;
      position:relative;
      margin-top:35px;

      @keyframes articleOn {
        0%{
          opacity:0;
        }
        100%{
          opacity:1;
          margin-top:0;
        }
      }

    }

    .article-off{
      animation:articleOff .9s 1;
      animation-fill-mode:forwards;
      position:relative;
      margin-top:35px;

      @keyframes articleOff {
        0%{
          opacity:0;
        }
        100%{
          opacity:1;
          margin-top:0;
        }
      }
    }


    div {
      margin-left:53px;
      text-align:left;

      @media(max-width:$tablet){
        margin-left:20px;
      }

      h1 {
        font-size:7vw;
        color:$primary-font-color;
        margin-bottom:0;
        line-height:80%;
        width:70%;
        margin-bottom:15px;
        animation:headingIn .5s 1;
        animation-fill-mode:forwards;
        position:relative;
        margin-top:25px;
           font-family:"Work Sans",sans-serif;
        text-transform:uppercase;

        @media(max-width:$tablet){
          font-size:50px;
        }

        @keyframes headingIn {
          0%{
            opacity:0;
          }
          100%{
            opacity:1;
            margin-top:0;
          }
        }

      }
      p {
        color:$primary-font-color;
        font-size:18px;
      }
      h3 {
        margin-top:0;
        color:$primary-font-color;
        width:60%;
        line-height:100%;
           font-family:"Work Sans",sans-serif;
        text-transform:uppercase;
        @media(max-width:$tablet){
          width:90%;
        }
      }
      ul {
        list-style:none;
        padding-left:0;

        p {
          margin-top:0;
        }
      }

     a {
        color: #b3b3b3;
        text-decoration: none;
        font-weight: bold;
        margin: 0 18px 0 0;
        font-size: 14px;
        transition:.5s;

        &:hover {
          color:$primary-font-color;
          transition:.3s;
        }
    }

      img {
        border-radius:100%;
        filter:grayscale(1);
        width:90px;
      }
      .blog-date {
        color:$primary-font-color;
        font-weight:bold;
        font-size:12px;
        margin-top:15px;
        display:block;
      }
    }
  }

  .blog-header-image {
    height: 250px;
    margin-left: 0;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    background-attachment: fixed;
    margin-bottom:50px;

    @media(max-width:$tablet){
      background-attachment:initial;
    }

      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
      }
  }

   h3.exerpt {
      text-transform:initial !important;  
      font-family:"Helvetica",arial,sans-serif;
    }

}
// site generic color

$secondary-base-color:#222;
$tertiary-base-color:#222;
$full-black:#fff;
$tertiary-black:#fff;
$primary-dark: #fff;
$tertiary-dark:#fff;
$primary-red:#fff;
$white:#fff;
$purple: #7601fd;
$black: #333;
$primary-font-color:#222;
$off-white:#fdfdfd;
$full-white: #fff;
$primary-transparent:rgba(0,0,0,0.3);
$tertiary-transparent:rgba(255,255,255,0.4);
$button-color: red;
$primary-red:#fff;

$primary_font: 'Darker Grotesque', sans-serif;
$secondary_font: 'Roboto', arial, sans-serif;
$sub_para_font: 'Darker Grotesque', sans-serif;

$tablet: 800px;

// page primary colors

$home-primary-color: #eee;
$about-primary-color: #eee;
$work-primary-color: #eee;
$team-primary-color: #eee;
$knowledge-primary-color:#eee;
$contact-primary-color: #eee;
$blog-primary-color:#eee;

// font families

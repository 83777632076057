@import '../../scss/variables.scss';
.blogs-wrapper.animate-in .blogs-content-preview p.exerpt p {
    font-size: 16px;
    line-height: 130%;
}
.blogs-wrapper.animate-in {
  position:relative;

    .blogs-text-wrapper {
      text-align: left;
      margin: 40px 0px 0px 0px;
      font-size: 18px;
      transform: skewX(-5deg);
      animation-delay: 1s;
      animation: caseIntroWrapper .6s 1;
      animation-fill-mode: forwards;
      margin-left: 0 !important;
      width:450px;

      @keyframes caseIntroWrapper {
        0%{
          transform:skewX(-5deg);
        }
        100%{ 
          transform:skewX(0deg);
        }
      }

      p {
        top:15px;
        opacity:0;
        position:relative;
        animation:caseIntroP 1.2s 1;
        animation-fill-mode:forwards;
        animation-delay:2s;
        width: 100%;
        transform: skewX(-5deg);
        color:$primary-font-color;
        font-size:18px;
        line-height:130%;
        @media(max-width:600px){
          max-width:300px;
        }

        @keyframes caseIntroP {
          0%{
            top:45px;
          }
          100%{
            top:0;
            opacity:1;
            transform: skewX(0deg);
          }
        }
      }
  }

  .number-item-wrapper {
      top:15px;
      opacity:0;
      z-index: 3;
      transition:.5s;

      span {
        @media(max-width:$tablet){
          display:none;
        }
      }


  }
  .no-results-wrapper {
    box-sizing: border-box;
    padding: 0px 0px 0px 33px;
    top:5px;
    position:relative;
    opacity:0;
    animation:noResultIn .6s 1;
    animation-fill-mode:forwards;

    @keyframes noResultIn {
      0%{
        top:5px;
      }
      100%{
        top:0;
        opacity:1;
      }
    }

    p{
      font-size: 20px;
      font-weight: bold;
      color:$primary-font-color;
    }
    a {
      color: $secondary-base-color;
      font-weight: bold;
      font-size: 17px;
  }
}
  .blog-view {

    min-height:280px;
    background-size:cover;
    background-position:center;


    &:hover .init-filter {
      width:100%;
      transition:.5s;
      background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);

    }
    &:hover span.filter-up {
      height:0;
      transition:.5s;
    }
    &:hover h6.author-blog-article, &:hover h6.author-job-title, &:hover p.exerpt,&:hover h6.date-blog-article {
     color:$full-white;
     transition:.4s;
     margin-left:5px;
     position:relative;

    }
    &:hover .date-blog-article {
      margin-left:5px;
      position:relative;
      transition:.4s;

    }
    &:hover h3 {
      color:$full-white;
      transition-delay:.3s;
      transition:.4s;
      margin-left:5px;
    }

  }

  span.init-filter {
    width: 60%;
    height: 100%;
    background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition:.3s;
    transition-delay:.5s;
}
span.filter-up {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);
    height: 100%;
    z-index: 0;
    transition:.5s;
}

    .blogs-content-preview{
      height: 100%;
      width: 60%;
      position: relative;
      box-sizing: border-box;
      padding: 20px;
      display:flex;
      align-items:center;

      @media(max-width:$tablet){
        width:70%;
        position:absolute;
      }

    h3 {
      box-sizing: border-box;
      padding: 0 20px;
      margin: 0px;
      line-height: 100%;
      color: #333;
      margin-top: 20px;
      position:relative;
      transition:.4s;
      color:$white;
      font-family:'Work Sans',sans-serif;
      text-transform:uppercase;
      line-height:130%;
  }

      h6 {
          box-sizing: border-box;
          padding: 0 20px;
          margin: 0px 0 0px 0px;
          position:relative;
           color:$white;
      }
      h6.author-blog-article {
          margin-top: 11px;
          font-size: 12px;
          color: $primary-dark;
          position:relative;
          transition:.4s;
           color:$white;
      }
      h6.author-job-title {
        color:$primary-dark;
        position:relative;
        transition:.4s;
         color:$white;
         margin-top:3px;
         margin-bottom:5px;
      }
      h6.date-blog-article {
          color: $white;
          position:relative;
          transition:.4s;
      }
      p.exerpt {
        color:$white;
        padding:0 20px;
        box-sizing:border-box;
        margin:0px 0px 26px 0;
        position:relative;
        transition:.4s;
         color:$white;
      }
    
    }


  .blog-article-color {
    background: #dcb97d;


     span.hover-view-item {
      position: absolute;
      z-index: 9;
      width: 0%;
      background: #c53d39;
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding: 0;
      color: #fdfdfd;
      transition:.9s;
      transition-delay:.5s;


      h2 {
        opacity:0;
        transition:.3s;
        font-size: 5vh;
      }
      p {
        opacity:0;
        transition:.3s;
      }
    }

    &:hover span.hover-view-item {
      width:100%;
      padding:40px;
      transition:.6s;
    }

    &:hover span.hover-view-item h2{
      opacity:1;
      transition-delay:.4s;
      position:relative;

    }
    &:hover span.hover-view-item p {
      opacity:1;
      transition-delay:.5s;
    }

  }
  
  div {
    background-size:cover;
    background-position:center;
    flex: 50%;
    text-align: left;
    position:relative;
  }
 


  .background-blog-articles {
    height: 180px;
  }


  .input-search-wrapper {

    div {
      margin-left: 0;
      margin-left: 175px;
      text-align:left;

      @media(max-width:$tablet){
        margin-left:25px;
      }

        label {
          display:block;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          color: $secondary-base-color;
          position: relative;
          animation: animatedTextSearch 2s 1;
          animation-fill-mode: forwards;
          animation-delay:1.5s;
          transition-delay:1.5s;
          opacity: 0;
          left:35px;
          font-weight: 900;

          @keyframes animatedTextSearch {
            0%{
              left:35px;
            }
            100%{
              left:0;
              opacity:1;
            }
          }
        }
        span {
          width: 8px;
          position: absolute;
          height: 2px;
          background: $primary-font-color;
          margin-top: 6px;
          left: -69px;
          opacity: 0;
          animation: spanOnInOne .6s 1;
          animation-fill-mode: forwards;
          animation-delay: 1.5s;
          top: 2.5px;

          @media(max-width:$tablet){
            display:none;
          }
        }
    
    }
  }

  .search-blogs.animate-in {

        width: 50px;
        height: 48px;
        border-bottom: 2px solid;
        border: none;
        background: none;
        font-size: 16px;
        animation: inputIn 1.2s 1;
        animation-fill-mode: forwards;
        animation-delay: 1.6s;
        opacity: 0;
        background: #7601fd;
        text-indent: 5px;
        margin-top: 16px;
        border-radius: 4px;
        top: 40px;
        font-weight: 900;
        transition: .9s;
        transition-delay: .9s;
        position: relative;
        margin-bottom: 40px;   
        color:$white;
        border-radius:0;

        @media(max-width:$tablet){
          width: 20px;
        }

        &::placeholder {
          color:$white;
          position:relative;
          left:5px;
        }
  }

  .search-blogs.animate-in.animate-show {
    width:600px;
    transition:1.2s;
    opacity:1;
    transition-delay:.3s;

    @media(max-width:$tablet){
      width:calc(100% - 60px);
    }
  }


  .cases-wrapper.animate-in {
      width: calc(100% - 130px);
      min-height: 400px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding-left: 130px;
      background: $primary-dark;
      margin-top:60px;
      margin-top: 50px;

      @media(max-width:$tablet){
        width: calc(100% - 25px);
        padding-left: 25px;
      }

       .number-item-wrapper {
          position: absolute;
          top:25px;
          opacity:0;
          z-index: 3;
          transition:.5s;
      }

      .blog-view {
        opacity:0;
        top:50px;
        transition:1s;
      }


      
   

      .blogs-flex-wrapper.animate-in {
            width: 100%;
            display: flex;
            flex-wrap: wrap; 

            @media(max-width:$tablet){
              display:block;
            }

        .blog-article-color {
            top:50px;
            transition:.9s;
            opacity:0;
            position:relative;
     

         
          }


       


        h5 {
          position: relative;
          font-size: 3vw;
          color: #fdfdfd;
          text-transform: capitalize;
          font-family: 'Roboto', sans-serif;
          font-weight: 900;
          margin:0;
          transition:.5s;
          text-align:left;

        }
        
      

        a {
          text-decoration:none;
        }

        .filter-hover {
            position: absolute;
            width: 100%;
            height: 100%;
            left:0;
            top:0;
            transition:.5s;



        }
        span.filter {
            position: absolute;
            bottom: 0;
            width: 40%;
            margin-left:60%;
            height: 100%;
            transition:.5s;
            background:rgba(51, 51, 51, 0.6);
            right: 0;
            transition-delay:.5s;
          
        }
    }
    


  }

    .cases-wrapper.animate-in.animate-show {

      .number-item-wrapper {
          position: absolute;
          top:0px;
          transition-delay:.4s;
          opacity:1;
          transition:.9s;
          z-index: 3;


      }
   
  }


   .blogs-flex-wrapper.animate-in.animate-show {

           .blog-view {
            top:0px;
            transition:.9s;
            opacity:1;
            position:relative;


             @for $i from 1 to 1000 {
               &:nth-child(#{$i}) { transition-delay: $i * 0.35s; }
           }
          }

        }

}



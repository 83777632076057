
@import '../../scss/variables.scss';

.body-content-wrapper.animate-in {
    width: calc(100% - 130px);
    min-height: 500px;
    background: $primary-dark;
    display: flex;
    padding-left:130px;
  

    @media(max-width:$tablet){
      padding-left: 25px;
    display: block;
    width: calc(100% - 25px);

    .miny-sub-heading-wrapper.animate-in {
        left: 0!important;
      }
    }
  p {
    line-height:130%;
  }


    .availability {
      color:$secondary_base_color;
      font-weight:bold;
      font-size:12px;
    }

    .scroll {
      position: absolute;
      top: -125px;
      left: -50px;
      color:$white;

      @media(max-width:$tablet){
        display:none;
      }

      button {
        background: none;
        border: none;
        border-bottom: 2px solid $secondary-base-color;
        padding-bottom: 9px;
        font-size: 13px;
        padding: 0;
        padding-bottom: 5px;
        font-weight: bold;
        text-transform: lowercase;
        color:$secondary-base-color;
      }
    }

    div {
      flex: 1;
      box-sizing: border-box;
      padding: 50px;
      text-align: right;
      min-height:400px;
      position:relative;

      @media(max-width:$tablet){
        padding:25px;
      }
  

  }
   h6.home-sub-row-heading{
        font-size: 2.3vw;
        font-weight: bold;
        width: 100%;
        padding-left: 65px;
        box-sizing: border-box;
        padding-left: 0;
        line-height: 85%;
        margin-bottom: 30px;
        top:65px;
        opacity:0;
        position:relative;
        transition:.5s;
        color:$secondary-base-color;
        font-family:'Work Sans', sans-serif;
        line-height:110%;

        @media(max-width:$tablet){
          font-size:27px;
          line-height:110%;
        }
      }
  div:nth-child(2) {
    background-image: url('https://digital-assets-web.s3.amazonaws.com/mesim-card-2.96d887a5cba61bf08332.png');
    background-size: cover;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    animation: initBodyTwo .9s 1;
    animation-fill-mode: forwards;
    top: 25px;
    opacity: 0;
    animation-delay: .8s;
    background-size: contain;
    background-color: #3300fd;
    background-size: 400px;
    background-position: -1866px;


      @media(max-width:$tablet){
        min-height:280px;
        padding-left:15px;
      }

      @keyframes initBodyTwo {
        0%{
          opacity:0;
          top:25px;
        }
        100%{
          opacity:1;
          top:0;
        }
      }

      &:hover .filter-hover {
          height:0%;
          transition:.4s;
          transition-delay:s;
           background:#3100fe;
        }

        &:hover .filter {
          margin-left:0%;
          width:100%;
          transition:.4s;
         
        }

        &:hover h5 {
          margin-left:25px;
          transition:.5s;
          color:#fdfdfd !important;

        }
        &:hover p {
          margin-left:25px;
          transition:.5s;
          padding-right:20%;
          border-top: 2px solid rgb(255,255,255) !important;
           color:#fdfdfd !important;
        }

   


        h5 {
          position: relative;
          font-size: 2em;
          color: $white;
          text-transform: capitalize;
          font-family: 'Work Sans','Roboto', sans-serif;
          font-weight: 900;
          margin:0;
          transition:.5s;
          text-align:left;
          text-transform:uppercase;

          @media(max-width:$tablet){
            font-size:40px;
          }

        }
        p{
          position: relative;
          color:$white;
          width: 200px;
          border-top: 2px solid $white;
          padding-top: 10px;
          transition:.5s;
          text-align:left;
          font-size:18px;
          margin-bottom:5px;
          line-height:130%;

        }
        p.date {
            border-top: none !important;
            font-size: 11px;
            color: $white;
            font-weight: bold;
        }
        p.collab {
          border: none !important;
         
          padding: 0;
          color: $white;
          font-weight: bold;
          font-size: 12px;
        }

        a {
          text-decoration:none;
        }

        .filter-hover {
            position: absolute;
            width: 0%;
            height: 100%;
            background: #292929eb;
            left:0;
            top:0;
            transition:.5s;
            animation:filterHoverInHome .9s 1;
            animation-fill-mode:forwards;
            animation-delay:1.2s;
            background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);


            @keyframes filterHoverInHome{
              0%{
                width:0%;
              }
              100%{
                width:60%;
              }
            }


             @media(max-width:$tablet){
             @keyframes filterHoverInHome{
              0%{
                width:0%;
              }
              100%{
                width:80%;
              }
            }
            }


        }

        span.init-filter {
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.3);
          left: 0;
          top: 0;
          position: absolute;
      }
        span.filter {
            position: absolute;
            bottom: 0;
            width: 60%;
            margin-left: 60%;
            height: 100%;
            transition: .5s;
            transition-delay: .5s;
            right: auto;
            left: 0;
            margin-left: 0;
            background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);



                  
        }
  }

  .animate-in:nth-child(1) {
     

     p {
      position:relative;
      top:45px;
       opacity:0;
       transition:.5s;
       font-size:19px;
       color:$secondary-base-color;
       font-size:18px;
       line-height:130%;
     }

  }
   .animate-in.animate-show:nth-child(1) {
     



    p{
      position:relative;
      top:0;
      opacity:1;
      transition:1.2s;
      transition-delay:.5s;
      line-height:130%;
    }

      h6.home-sub-row-heading{ 
          top:0;
          transition:1.2s;
          opacity:1;
         }

  }

 

  .animate-in:nth-child(2) {


      .filter-hover {
        height:0%;
        transition:.6s;
        transition-delay:.3s;
      }

      .filter {
        margin-left:0%;
        width:100%;
        transition:.6s;
        transition-delay:.4s;

      }

       h5 {
        margin-left:25px;
        transition:.5s;
        transition-delay:.3s;
        color:$secondary-base-color;
      }
      p {
        margin-left:25px;
        transition:.5s;
        padding-right:20%;
        font-size:16px;
        line-height:130%;
      }


 
 

  }


  .animate-in.animate-show:nth-child(2) {




      .filter-hover {
        height:100%;
        transition:.6s;
        transition-delay:.5s;
      }
      

      .filter {
        margin-left:30%;
        width:40%;
        transition:.9s;
        transition-delay:.8s;

      }

       h5 {
        margin-left:25px;
        transition:.5s;
        transition-delay:.7s;
        color:$full-white;
      }
      p {
        margin-left:25px;
        transition:.5s;
        padding-right:20%;
      }

      &:hover .filter-hover {
        height:0%;
        transition:.6s;
        transition-delay:.5s;
      }

      &:hover .filter {
        margin-left:0%;
        width:100%;
        transition:.6s;
      }

      &:hover h5 {
        margin-left:0px;
        transition:.5s;
        transition-delay:.3s;
        color:#fff;
      }
      &:hover p {
        margin-left:0px;
        transition:.5s;
        padding-right:20%;
      }
 

  }
}



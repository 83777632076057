@import '../../scss/variables.scss';


.footer-wrapper {
    display: flex;
    flex-wrap:wrap;
    background:$primary-dark;
    position:relative;

    @media(max-width:$tablet){
      display:block;
    }

    a {
      transition:1s;
      &:hover {
        opacity: .4;
        transition:.4s;
      }
    }

    .footer-heading.location {
      background:$purple;
      color:$white !important;
      margin-bottom:35px !important;
      @media(max-width:$tablet) {
        background:none !important;
        color:$black !important;

      }
    }

    div:nth-child(1),div:nth-child(2){
      flex:50%;
    }
    .copyright-row {
      flex:100%;
      height:50px;
      border-top: 1px solid #f5f5f5;
       ul {
        padding-left:167px;
        box-sizing:border-box;
        position:relative;
        top:12px;
        @media(max-width:$tablet){
          padding-left:0px;
          padding-bottom:50px;
        }
        li {
          font-size:12px;
          display:inline-block;
          font-weight:bold;
          @media(max-width:$tablet){
            display:block;
            padding-bottom:15px;
          }
          a{
            color:$secondary-base-color;
            font-size:12px;
            text-decoration:none;
            transition:1s;

            &:hover {
              transition:.3s;
              color:$primary-font-color;
            }
          }
        }
      }
    }

    .footer-socials {
      display:inline-block;

      img {
        width:35px;
        height:35px;
      }
    }
    ul {
        list-style: none;
        text-align: left;
        padding: 0;
        margin-top:0;

        li {
          padding-left:15px;
          color:$secondary-base-color;
           font-weight:bold;
           font-size:21px;

           @media(max-width:$tablet){
            padding-left:20px;
           }
        }
     }

     .sub-wrap-one {
      border-right:1px solid #f5f5f5;
      padding-bottom:50px;

      @media(max-width:$tablet){
            padding-bottom: 5px;
      }

        ul {

          .footer-heading {
            padding-bottom: 15px;
            border-bottom: 1px solid #f5f5f5;
            border-bottom: 1px solid #f5f5f5;
            font-size: 12px;
            color:$secondary-base-color;
            padding-left: 175px;
            padding-top: 15px;
            margin-bottom: 15px;
            font-weight:bold;
            text-transform:uppercase;
            background:$purple;
            color:$white;
            margin-bottom:35px;
            @media(max-width:$tablet) {
              background:none;
              color:$black;

            }



            @media(max-width:$tablet){
              padding-left:20px;
            }
          }

          li{
            padding-left:175px;
            box-sizing:border-box;

            @media(max-width:$tablet){
              padding-left:20px;
            }


            a {
                font-size: 4vw;
                font-weight: bold;
                text-decoration: none;
                color: $secondary-base-color;
                font-family: 'Work Sans', sans-serif;
                font-weight: 900;
                line-height: 95%;
                transition:1s;
                text-decoration:none;
                text-transform:uppercase;


                &:hover {
                  opacity:.4;
                  transition:.5s;
                }

                @media(max-width:$tablet){
                  font-size:40px;
                }

                &:hover {
                  transition:.3s;
                  color:$primary-font-color;
                }
          
              }
          }
      
        }
     }


     .sub-wrap-two  {

        border-right: 1px solid #f5f5f5;

        .footer-heading {
          padding-bottom: 15px;
          border-bottom: 1px solid #f5f5f5;
          border-bottom: 1px solid #f5f5f5;
          font-size: 12px;
          color:$secondary-base-color;
          padding-left: 15px;
          padding-top: 15px;
          margin-bottom: 15px;
          font-weight:bold;
          text-transform:uppercase;

          @media(max-width:$tablet){
            padding-left:20px;
          }
      }

      a {
        color:$secondary-base-color;
        text-decoration:none;
        transition:1s;
        font-size:25px;
        font-family:'Work Sans', sans-serif;
        text-transform:uppercase;

        &:hover {
          color:$primary-font-color;
          transition:.3s;
        }
      }

      .footer-heading-sub {
        margin-top:15px;
      }
  }
  div {

      flex: 1;
      box-sizing: border-box;
      padding-left: 0;
  }
}




@import '../../scss/variables.scss';

.home-header-wrapper {
    width: 100%;
    height: 85vh;
    background: $primary-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    position:relative;

    @media(max-width:$tablet){
      height:550px;
    }
    .animatedTextHeader {
      margin-left:53px;
      @media(max-width:$tablet){
        margin-left:20px;
      }
    }
    span#home-head-sub-p {
      position:relative;
      top:-25px !important;
    }
    .sub-two {
      position: absolute;
      width: 50%;
      margin-left: 55%;
      margin-top: 13vh;
      left:0;
      @media(max-width:$tablet){
        width: 100%;
        margin-left: 0;
        margin-top: 28vh;
        /* display: none; */
        opacity: .3;
      }
    }
    .sub-one {
      position:absolute;
      width: 100%;
      left: 0;
      z-index: 2;
    }

      span.background-title {
      position: absolute;
    font-size: 100vh;
    left: 55px;
    opacity: .09;
    bottom: -25vh;
    font-weight: 900;
    letter-spacing: -2px;
    animation: backgroundTitleIn 1.7s 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    margin-top: 100px;
    color: #2196f3;
    padding-top: 100px;
    top: -28vh;
    bottom: auto;
    margin-top: 0;
    padding-top: 0;

      @keyframes backgroundTitleIn {
        0%{
          left:55px;
        }
        100%{
          left:0;
        }
      }
  }




    .fadeOut {
      visibility: hidden;
      opacity: 0; 
      transition: all .5s ease-out;
      transform: translate(20px,0px);
    }

    span.number {

      display: block;
      height: 100%;
      position: relative;
      height: 1px;
      position: absolute;
      left: 0;
      top: 0px;
      width: 50px;
      display:none;

      @media(max-width:$tablet){
        left:60px;
      }
    

       span.line {
        background: $primary-font-color;
        height: 2px;
        width: 0%;
        bottom: -3px;
        position: absolute;
        animation: lineUp .8s 1;
        animation-fill-mode: forwards;
        animation-delay: .5s;
        left: -60px;

        @keyframes lineUp{
          0% {
            width:0;
          }
          100%{
            width:100%;
          }
        }


    }
  }


  span.the-number-two {
      position: relative;
      font-size: 13px;
      left: 35px;
      top: -10px;
      animation: numberInTwo .5s 1;
      animation-fill-mode: forwards;
      animation-delay: 1s;
      opacity: 0;
      font-size: 13px;
      top: -10px;
      font-weight:bold;
      text-transform: uppercase;
      color: $secondary_base_color;

      @keyframes numberInTwo {
        0%{
          opacity:0;
          left:15px;
        }
        100%{
          left:5px;
          opacity:1;
        }
      }
  }

    span.title-in {
      display:inline-block;
      transform: skewX(125deg);
      animation:titleIn .5s 1;
      animation-fill-mode:forwards;
      animation-delay:.5s;

      @keyframes titleIn {
        0%{
          transform:skewX(125deg);
        } 
        100%{
          transform:skewX(0deg);
        }
      }
   
      .animated-text {
        position:relative;
        top:15px;
        animation:animatedText .5s 1;
        animation-fill-mode:forwards;
        opacity:0;

        @keyframes animatedText {
          0%{
            top:15px;
          }
          100%{
            top:0;
            opacity:1;
          }
        }




      }

     @for $i from 1 to 1000 {

        .animated-text:nth-child(#{$i}) { animation-delay: $i * 0.10s; }

      }

      .animated-text-para {
        position:relative;
        top:15px;
        animation:animatedText .5s 1;
        animation-fill-mode:forwards;
        opacity:0;
        animation-delay:.8s;

        @keyframes animatedText {
          0%{
            top:15px;
          }
          100%{
            top:0;
            opacity:1;
          }
        }




      }

      @for $i from 1 to 1000 {

        .animated-text-para:nth-child(#{$i}) { animation-delay: $i * 0.02s; }

      }

    
      span {
        position:relative;
        top:40px;
        transform: translate();
        animation:innerTextIn .6s 1;
         animation-delay:1s;
        animation-fill-mode:forwards;
        opacity:0;

        @keyframes innerTextIn {
          0%{
            top:40px;
            opacity:0;
          }
          100%{
            top:0;
            opacity:1;
          }
        }
      }
    }

    .transition-wrapper {
      position: fixed;
      width: 100%;
      height: 100vh;
      background: linear-gradient(270deg, #2800fe, #8200fd, #5f0ae7);
      display: flex;
      align-items: center;
      z-index:9;
      animation:transitionWrapper 1.2s 1;
      animation-fill-mode:forwards;
      bottom:0;



      @keyframes transitionWrapper {
        0%{
          height:100%;
        }
       
        100%{
          height:0;
        }
      }

      h3 {
        font-family:$primary-font;
        font-size:100vh;
        color:$white;
        animation:transitionText 3.5s 1;
        animation-fill-mode:forwards;
        transition-delay:2.5s;
        margin-left:100%;

        @keyframes transitionText {
          0%{
            margin-left:100%;
            opacity:0;
          }
          50%{
            margin-left:-100%;
            opacity:1;
          }
          100%{
            opacity:1;
            margin-left:-150%;
          }
        }
      }
  }

    .changer-initial {
      opacity: 1; 
      transition: all .3s ease-out;
      transform: translate(0px,0px);
      position:relative;
    }

    .changer-wrapper {
      animation:changerIn .8s 1;
      animation-fill-mode:forwards;
      opacity:0;
      animation-delay:1.4s;

      @keyframes changerIn {
        0%{
          opacity:0;
        }
        100%{
          opacity:1;
        }
      }
    }

    span#changer {
      text-transform: capitalize;
      color: #7601fd;
      display: inline-block;
      margin-top: 15px;
      padding-bottom: 15px;
      text-transform:uppercase;
      margin-top:0;
     
    }
    span#footprint {
      position: relative;
      top: -13px !important;
  }


    h2 {

      font-size:5vw;
      color: $primary-font-color;
      font-family: "Roboto", sans-serif;
      font-weight: 900;
      position: relative;
      line-height: 85%;
      padding-bottom: 0;
      margin-bottom: 21px;
      margin-top: 0;
      top: 15px;
      transform: skewX(-25deg);
      animation: titleIn 2s 1;
      animation-fill-mode: forwards;
      margin-bottom: 40px;
      text-transform:uppercase;
      font-family:'Work Sans', sans-serif;

      @media(max-width:$tablet){
        font-size:50px;
      }

      @keyframes titleIn {
        0%{
          transform:skewX(-25deg);
          top:15px;
        } 
        100%{
          transform:skewX(0deg);
          top:0;
        }
      }
     
    }
  

 
  p {
      color:$primary-font-color;
      font-size: 1.75vw;
      margin-top: 0;
      font-family: 'Roboto';
      font-weight: 900;
      width: 100%;
      line-height: 100%;

      @media(max-width:$tablet){
        width:auto;
        font-size:21px;
      }
  }

  div {
    width: 65%;
    text-align: left;
    position: relative;
    margin: auto;

    @media(max-width:$tablet){
      width:auto;
    }
  }
}
@import '../../scss/variables.scss';

.contact-form-wrapper {
  padding-bottom: 100px;
  z-index: 2;
  position: relative;

  * {
    -webkit-appearance:none;
    -moz-appearance:none;
  }
  .contact-text-wrapper {

      transform: skewX(-5deg);
      animation-delay:.3s;
      animation:caseIntroWrapper .6s 1;
      animation-fill-mode:forwards;

      @media(max-width:$tablet){
        margin-left:25px !important;
      }

      @keyframes caseIntroWrapper {
        0%{
          transform:skewX(-5deg);
        }
        100%{ 
          transform:skewX(0deg);
        }
      }

    p {
        top:15px;
        opacity:0;
        position:relative;
        animation:caseIntroP 1.2s 1;
        animation-fill-mode:forwards;
        animation-delay:2s;
        width: 40%;
        transform: skewX(-5deg);
        color:$primary-font-color;

        @media(max-width:$tablet){
          width:auto;
        }

        @keyframes caseIntroP {
          0%{
            top:45px;
          }
          100%{
            top:0;
            opacity:1;
            transform: skewX(0deg);
          }
        }
      }

    a {
      font-weight:bold;
      color:#222;
    }
  }
  p.contact-intro-text {
    padding-top:15px;
    color:$primary-font-color;
  }

  .boilerform.animate-in {
     margin-left:175px;
     opacity:0;
     position:relative;
     top:55px;
     transition:1s;

     @media(max-width:$tablet){
      margin-left:25px;
     }

      button.c-button {
    background: #2b2b2b;
    color: #eee;
    font-size: 19px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    /* padding: 4px 12px 6px 15px; */
    background: #8353fd;
    margin-left: 2px;
    color: #fff;
    text-transform: uppercase;
    transition: 1s;
    padding: 10px;
    max-width: 250px;
    width: 100%;
    font-family:'Work Sans',sans-serif;

      &:hover {
        background:#eee;
        color:$tertiary-base-color;
        transition:.3s;
    }
  }
    fieldset.c-form {
      border:none;
      padding-left: 0;
      margin-left: 0;
    }
  .c-form__row {
    display: flex;
    align-items: center;
    position: relative;

    @media(max-width:$tablet){
      display: block;
    width: calc(100% - 15px);
    text-align: left;

      div {
        text-align:left;
      }
    }
  }
  label.c-label {
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #8353fd;
    transition:.5s;
    opacity:0;
    margin-left: 25px;
    top:0;
    color:#222;
    font-family:'Work Sans',sans-serif;

    @media(max-width:$tablet){
      position:relative;
    }
  }
  input {
    height: 48px;
    border-bottom: 2px solid;
    border: none;
    background: none;
    font-size: 16px;
    text-indent: 5px;
    font-weight: 900;
    transition: .9s;
    transition-delay: .9s;
    position: relative;
    margin-bottom: 40px;
    margin: 23px 19px 10px 2px;
    width: 250px;
    background:#eee;
    color:$white;
    border-bottom: 2px solid #7601fd;

    @media(max-width:$tablet){
      width:calc(100%);
    }
  }
  textarea {
      height: auto;
      border-bottom: 2px solid;
      border: none;
      background: none;
      font-size: 16px;
      font-weight: 900;
      transition: .9s;
      transition-delay: .9s;
      position: relative;
      margin-bottom: 40px;
      margin: 25px 19px 10px 2px;
      width: 521px;
      color:$white;
      padding:5px;
      background:#eee;
      border-bottom:2px solid #8353fd;


      @media(max-width:$tablet){
        width:calc(100%);
      }

    }
  }

  .boilerform.animate-in.animate-show {
      opacity:1;
     position:relative;
     top:0;
     transition:.9s;
     transition-delay:.9s;




     .c-form__row div:nth-child(1) {
      label.c-label {
        opacity:1;
        transition-delay:1.20s;
        margin-left:3px;
      }
     }
     .c-form__row div:nth-child(2) {
      label.c-label {
        opacity:1;
        transition-delay:1.5s;
        margin-left:3px;
      }
     }
     .c-form__row div:nth-child(3) {
      label.c-label {
        opacity:1;
        transition-delay:2s;
        margin-left:3px;
      }
     }
     .c-form__row:nth-child(3) {
      label.c-label {
        opacity:1;
        transition-delay:1.20s;
        margin-left:3px;

      }
     }
    


  }
 
 

  .contact-text-wrapper {
    margin-left:175px;
    padding-top:15px;
    padding-bottom:50px;

    p.not-available {
      font-weight: bold;
      font-size: 12px;
      color: #222; 
      margin-bottom:15px;

    }
    p {
      text-align:left;
      margin:0;
      font-size:18px;
      font-size:18px;
      line-height:130%;
    }
  }
}
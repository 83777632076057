@import '../../scss/variables.scss';

  .projects-wrapper {
      margin: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      animation:navIn 2s 1;
      animation-delay:.5s;
      animation-fill-mode:forwards;
      position:relative;
      opacity:0;
      left:100px;
      div.card {
          height:200px;
          width: 33.33%;
          background:purple;
          display: flex;
          margin: 15px;
          box-sizing: border-box;
          padding: 10px;
          font-family: 'Work Sans', sans-serif;
          text-transform: uppercase;
          cursor:pointer;
          transition:.4s;
          border:15px solid orange;
          text-align:left;
          border-radius:25px;
          &:hover {
            background:red;
            transiton:.4s;
          }
      }
  
  }

.header-component-wrapper {
  position:absolute;
  width:100%;
  z-index:999;


  .header-view-left a {
    font-family:"Work Sans",sans-serif !important;
      text-transform:uppercase;
  }
  .header-view-right {
    
    font-family:"Work Sans",sans-serif;
    text-transform:uppercase;
  
    a {

    font-family:"Work Sans",sans-serif;
    text-transform:uppercase;
    font-weight:bold;
    
    }
  }


  .header-navigation-sub {

    a {
      transition:1s;
      color:#7601fd;
      text-decoration:none;
      font-size:25px;

      &:hover {
        color:$primary-font-color;
      }
    }
    .blink_dot {
      animation:blinkDot 1.2s infinite;

      @keyframes blinkDot {
        0%{
          opacity:1;
        }
        50%{
          opacity:0;
        }
        100%{
          opacity:1;
        }
      }

    }
    .grape-active {
      
      animation:grapeActive 1s 1;
      animation-fill-mode:forwards;
      left:25px;
      position:relative;

      @keyframes grapeActive {
        0%{
          color:rgba(255,255,255,0.0);
          left:25px;
        }
        50%{
          color:rgba(255,255,255,0.0);
        }
        100%{
          color:$white;
          left:0;
        }
      }
    }
    
  }

  .modal.modal-on {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0px 25px;
    background: $secondary-base-color;
    color: $white;
    font-weight: bold;
    cursor:pointer;
    z-index:9;
    bottom:-55px;
    animation:modalIn .5s 1;
    animation-fill-mode:forwards;
    transition:.4s;
    -webkit-box-shadow: 0px 0px 47px -20px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 47px -20px rgba(0,0,0,0.75);
    background:linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);

    &:hover {
      transition: .5s;
      background:$primary-font-color;
    }

    @keyframes modalIn {
      0%{
        bottom:-55px;
      }
      100%{
        bottom:0;
      }
    }


    img {
      width: 10px;
      position: absolute;
      left: 7px;
      top: 7px;
      fill:$white;
      filter:invert(1);
    }
    a {
       color:$white;
      text-decoration: none;
      margin-left: 5px;
      font-size:20px;
      top:1px;
      transition:.4s;

      &:hover {
        color:wheat;
        transition:.3s;
      }
    }
  }

  .modal.modal-off {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0px 25px;
    background: #d6382c;
    color: $primary-font-color;
    font-weight: bold;
    cursor:pointer;
    z-index:9;
    bottom:0;
    animation:modalOff .5s 1;
    animation-fill-mode:forwards;

    @keyframes modalOff {
      0{
        bottom:0;
      }
      100%{
        bottom:-55px;
      }
    }


    img {
      width: 10px;
      position: absolute;
      left: 7px;
      top: 7px;
    }
    a {
       color: #111;
      text-decoration: none;
      margin-left: 5px;
      font-size:20px;
      top:1px;
      transition:.4s;

      &:hover {
        color:wheat;
        transition:.3s;
      }
    }
  }



  .demonstrate {
    text-align: left;
    top: 0;
    position: fixed;
    background: #ff2925;
    color: #eee;
    font-weight: 700;
    display: block;
    box-sizing: border-box;
    padding: 5px 33px;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 439px;
    bottom: 0;
    top: auto;
    text-transform: uppercase;
    right: 0;
    margin-right: 0;

  }
  /* burger menu */
  .hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: $primary-dark; }

.hamburger-box {
      width: 40px;
    height: 24px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

   }


.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 25px;
    text-align: center;
    height: 4px;
    background-color: #7601fd;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    margin: auto; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -7px; }
  .hamburger-inner::after {
    bottom: -7px; }

    .hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }


.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
  background-color:#fdfdfd; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
  background-color:#fdfdfd;
   }

/* other */

.header-navigation {
  display:flex;
  position:relative;
  z-index:9;
   padding:0 37px;
   @media(max-width:$tablet){
    padding:0;
   }

  div{
    flex:1;
    padding:10px;
    box-sizing:border-box;
  }
  div:nth-child(2){
    text-align:right;
  }

  div:nth-child(1){
    text-align:left;
  }
}



/* header nav not clicked */
.header-view {
    position: absolute;
    width: 100%;
    display: flex;
    top: 0;
    height:0;
    overflow:hidden;
    transition:.5s;

    @media(max-width:$tablet){
      display:block;
    }


    .header-view-sub{
      flex:1;
      transition:height .5s;

      ul {
        opacity:0;
      }

    }



    .header-view-sub.header-view-left {
      transition:.6s;
      height:0;
      box-sizing:border-box;
      border-bottom:1px solid $tertiary-transparent;
      display:flex;
      justify-content:center;
      align-items:center;
      text-align:right;
      background:linear-gradient(to left,  rgb(129, 0, 253) 1%,rgb(97, 0, 253) 43%,rgb(78, 0, 253) 73%,rgb(45, 0, 254) 100%);

      @media(max-width:$tablet){
        text-align:left;
             }


     span {
      width:100%;
      display:block;
     }

      .Home.link-on {
        color:$home-primary-color;
        transition:.6s;

      }
      .About.link-on {
        color:$about-primary-color;
        transition:.6s;
      }
      .Work.link-on {
        color:$work-primary-color;
        transition:.6s;
      }
      .Team.link-on {
        color:$team-primary-color;
        transition:.6s;
      }
      .Knowledge.link-on {
        color:$knowledge-primary-color;
        transition:.6s;
      }
      .Contact.link-on{
        color:$contact-primary-color;
        transition:.6s;
      }
      .Blog.link-on {
        color:$blog-primary-color;
        transition:.6s;
      }


      a {
   
       font-size: 5vw;
      line-height: 99%;
      font-family: $secondary-font;
      font-weight: 900;
      color: $white;
      padding-right: 25px;
      text-decoration: none;
      letter-spacing:-2px;
      font-family:'Work Sans',sans-serif;
      text-transform:uppercase;
      font-family:"Work Sans",sans-serif;
      text-transform:uppercase;

      @media(max-width:$tablet){
        font-size:50px;
        padding-left:20px;
      }

        &:hover {
          padding-right:35px;
          transition:.4s;
        }
    } 
    ul {
      list-style:none;
      padding-left:0;

      li.header-view-heading {
          font-weight: bold;
          font-size: 12px;
          border-bottom:1px solid $tertiary-transparent;
          padding-bottom: 5px;
          margin-bottom: 5px;
          color: $primary-dark;
          padding-right: 250px;
          display: inline-block;
          font-family:$primary-font;
          text-transform:uppercase;
          font-family: 'Work Sans',sans-serif;
           text-transform: uppercase;
          @media(max-width:$tablet){
            padding-left:20px;
          }

      }

      
    }
   }

    .header-view-sub.header-view-right {
      transition:.6s;
      height:0;
      box-sizing:border-box;
      border-left:1px solid $tertiary-transparent;
      border-bottom:1px solid $tertiary-transparent;
      display:flex;
      align-items:center;
      text-align:left;
      background:linear-gradient(to right,  rgb(129, 0, 253) 1%,rgb(97, 0, 253) 43%,rgb(78, 0, 253) 73%,rgb(45, 0, 254) 100%);

      ul {
        list-style: none;
        padding-left: 0;

        a {
          color:$primary-dark;
          line-height:140%;
          text-decoration:none;
          font-weight:900;
          line-height:80%;
          transition:.5s;

          &:hover {
            color:#eee;
            transition:.3s;
          }

        }

        li {
          padding-left:25px;
          font-size:2vw;
          font-weight:900;
          line-height:80%;
          color:$primary-dark;

        }
        li.header-view-heading {
          font-size: 12px;
          border-bottom:1px solid $tertiary-transparent;
          padding-bottom: 5px;
          margin-bottom: 5px;
          color:$primary-dark;
          text-transform: uppercase;
          margin-bottom: 20px;
          margin-top: 25px;
        }
      }

    }
    .header-view-sub.header-view-bottom {
      width: 100%;
      height: 00vh;
      background: #6502fd;
      bottom: 0;
      position: fixed;
      transition:.5s;
      transition-delay:0;

      div.social-link-list {

          display:flex;
          align-items:center;
          justify-content:center;
          left:39px;
          position:relative;

         ul {
          opacity:1;
          list-style:none;
          display:flex;
          align-items:center;
          padding:15px 27px;
          margin:0;
          position:relative;
          left:54px;
          border-left:1px solid $tertiary-transparent;

          img {
            width:27px;
            filter:invert(1)
          }
          li {
            font-size:12px;
            color:$primary-dark;
            font-weight:bold;
            font-family:$primary-font;
            text-transform:uppercase;

            a {
              padding:10px 5px;
            }
          }
        }

      }

     
     
    }
    .Home.header-view-sub.header-view-bottom {
      background-image:url('/static/media/octopus-blue.920137de.jpeg');
      background-size:cover;
    }
    
    .About.header-view-sub.header-view-bottom {
       background-image:url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size:cover;
    }
    
    .Work.header-view-sub.header-view-bottom {
       background-image:url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size:cover;
    }
    
    .Team.header-view-sub.header-view-bottom {
       background-image:url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size:cover;

    }
    
    .Contact.header-view-sub.header-view-bottom {
       background-image:url('/static/media/octopus-blue.920137de.jpeg');
      background-size:cover;
    }

    .Knowledge.header-view-sub.header-view-bottom {
     background-image:url('/static/media/octopus-blue.920137de.jpeg');
      background-size:cover;
    }
    .Blog.header-view-sub.header-view-bottom {
      background-image:url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size:cover;
    }
    
}


/* header nav clicked! */
.header-view.header-view-show {
  height:80vh;


  .header-view-sub.header-view-left {
       height: 100%;
    transition: height .5s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    overflow:hidden;
    

    @media(max-width:$tablet){
      text-align:left;
    }


    a {
  
      opacity:1;
      font-family:"Work Sans",sans-serif;
      text-transform:uppercase;

    } 
 
//    @for $i from 1 to 1000 {
//    a:nth-child(#{$i}) { transition-delay: $i * 0.8s; }
//     }


    span {
      width:100%;
    }

    img {
      width:20vw; 
    }

    ul {
      opacity:1;
    }
  }
  .header-view-sub.header-view-right {
       height:100%;
      transition-delay:2.5s;
      transition:height .8s;
      

       ul {
        opacity:1;
      }
    }

    .header-view-sub.header-view-bottom {
      height: 20vh;
      transition:.5s;
    }
}

}
@import '../../scss/variables.scss';

span.miny-sub-heading-wrapper.animate-in {
    display: block;
    top: 0;
    position: relative;
    width: 0px;
    margin-bottom: 15px;

    span:nth-child(1) {
      width: 8px;
      position: absolute;
      height: 2px;
      background: $primary-font-color;
      margin-top: 10px;
      left: 0;
      opacity:0;
      transition:.9s;
  }

  span:nth-child(2){
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    color: $secondary-base-color;
    transition:.9s;
    opacity:0;
    position:relative;
    right:-10px;
    top:3px;

  }
}


span.miny-sub-heading-wrapper.animate-in.animate-show {


    span:nth-child(1) {

      width:50px;
      transition:.6s;
      transition-delay:.5s;
      opacity:1;
      top:2.5px;


    }
     span:nth-child(2) {
      opacity:1;
      right:15px;
      transition:.9s;
      transition-delay:.8s;
     }

}
@import '../../scss/variables.scss';

span.number-item-wrapper {
   
    text-align: left;
    display: block;
    border-top: 2px solid $secondary-base-color;
    display: inline-block;
    font-size: 13px;
    box-sizing: border-box;
    padding-top: 5px;
    float: left;
    font-weight:bold;
    font-size:10px;

    span {
        display: block;
        color:$primary-font-color;
    }

  span:nth-child(1){
      font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    padding-bottom: 5px;
  }


}
@import '../../scss/variables.scss';

.not-found-component-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    .four-wrap {
    	width:100%;
    	max-width:400px;
    }
}